<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  route
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="closeDialog">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                  v-model="route.title"  outlined dense label= "Route title">
                            </v-text-field>
                          <span class="text-danger" v-if="$v.route.title.$error">This information is required</span>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                    :items="routes_categories"
                                    item-value="id"
                                    item-text="title"
                                    v-model="route.route_category_id"
                                    outlined
                                    dense
                                    label= "Route category">

                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="route.route_code" outlined dense label= "Route code">
                            </v-text-field>
                        </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="route.fee" outlined dense label= "Monthly fee">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                            <v-switch v-model="route.is_active" outlined dense label="Active">
                            </v-switch>
                      </v-col>


                        <v-col cols="12">
                            <div class="float-right">
                                <v-btn depressed @click="closeDialog();"  class="text-gray btn btn-standard cancel-btn">Cancel</v-btn>
                                <v-btn v-if="checkIsAccessible('route', 'create')" depressed :loading="isBusy" @click="createOrUpdate"  class="ml-2 text-white btn btn-primary">Save</v-btn>
                            </div>

                        </v-col>

                    </v-row>
                </v-card-text>

            </v-card>

        </v-dialog>

    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import RoutesService from "@/core/services/Transport/Routes/RoutesService";
    const routesService = new RoutesService();
    import RoutesCategoryService from "@/core/services/Transport/RoutesCategory/RoutesCategoryService";
    const routesCategoryService = new RoutesCategoryService();
    export default {
        validations: {
            route: {
                title: {required}
            }
        },
        data() {
            return {
                edit: false,
                dialog: false,
                sizeExceed: false,
                isBusy: false,
                routes_categories: [],
                route: {
                    title: null,
                    route_category_id: null,
                    route_code: null,
                    fee:null,
                    fee_frequency:null,
                    is_active: true
                }
            };
        },

        methods: {

            __getCategories() {
                routesCategoryService
                    .paginate(this.search)
                    .then(response => {
                        this.routes_categories = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
          closeDialog(){
            this.dialog = false;
            this.resetForm();
          },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.__get(id)
                }
               this.dialog = true;
                this.__getCategories()
            },
          createRoute(){
            this.edit = false;
            this.dialog = true;
            this.resetForm();
            this.__getCategories()
          },
          editRoute(id){
            this.edit = true;
            this.dialog = true;
            this.__get(id);
            this.__getCategories()
          },

            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, __get(id) {
                routesService.show(id).then(response => {
                    this.route = response.data.route
                })
            },
            // generateFd() {
            //     // console.log(this.video)
            //     let fd = new FormData();
            //     for (let key in this.route) {
            //         if (key == "is_active" && this.video["is_active"]) {
            //             fd.append("is_active", this.video ? 1 : 0);
            //         } else {
            //             if (this.video[key] != null) {
            //                 fd.append(key, this.video[key]);
            //             }
            //         }
            //     }
            //     return fd;
            // },
            createOrUpdate() {
                this.$v.route.$touch();
                if (this.$v.route.$error) {
                    setTimeout(() => {
                        this.$v.route.$reset();
                    }, 3000);
                } else {
                    let fd = this.route;
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                routesService
                    .update(this.route.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.dialog=false;
                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            __create(fd) {
                this.isBusy = true;
                routesService
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                      this.dialog=false;
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.route.$reset();
                this.edit = false;
                this.route = {
                  title: null,
                  route_category_id: null,
                  route_code: null,
                  fee:null,
                  fee_frequency:null,
                  is_active: true
                };

                this.$emit("refresh");
            }
        }
    };
</script>
